<template>
  <div class="topbg-wrapper">
    <div class="top-bg">
      <div class="top-header " v-if="!$isInMobile">
        <div class="header-between flex-item"></div>
        <div class="header-center">IDO</div>
        <div class="header-between flex-item flex-end">
          <div class="coin-box">100.00USDT</div>
          <div class="wallet-box ml">{{ $t("鏈接錢包") }}</div>
        </div>
      </div>
    </div>
    <div class="top-row flex-middle">
      <div class="row-item flex-item">
        <div class="row-text-line flex-middle">
          <span class="text-left">{{ $t("活動名稱：") }}</span>
          <span class="text-right">{{ $t("私募活動26") }}</span>
        </div>
        <div class="row-text-line flex-middle">
          <span class="text-left">{{ $t("活動時間：") }}</span>
          <span class="text-right">2022/01/10 10:00:00---2022/02/15</span>
        </div>
        <div class="row-text-line flex-middle">
          <span class="text-left">{{ $t("鎖倉幣種：") }}</span>
          <span class="text-right">USDQ</span>
        </div>
        <div class="row-text-line flex-middle">
          <span class="text-left">{{ $t("收益釋放：") }}</span>
          <span class="text-right">￥10</span>
        </div>
        <div class="row-text-line flex-middle">
          <span class="text-left">{{ $t("鎖倉時長：") }}</span>
          <span class="text-right">{{ $t("10個月") }}</span>
        </div>
        <div class="row-item-left-btn">{{ $t("購買") }}</div>
      </div>
      <div
        v-show="$isInMobile ? hide : true"
        class="row-item flex-middle flex-item el-1px-l"
      >
        <img class="ido-top" src="~@/assets/image/pc/ido-top.png" alt="" />
        <div class="item-right-box">
          <div class="primary-color">{{ $t("綁定上級，額外獲得獎勵") }}</div>
          <div class="text-color">{{ $t("暫未綁定，快去綁定") }}</div>
          <div class="right-btn">{{ $t("去綁定") }}</div>
        </div>
      </div>
      <div v-show="!$isInMobile ? hide : 'true'" class="item">
        <div class="item-flex">
          <div class="item-nav">
            <div class="primary-color">{{ $t("綁定上級，額外獲得獎勵") }}</div>
            <div class="text-color">{{ $t("暫未綁定，快去綁定") }}</div>
          </div>

          <div class="right-btn">{{ $t("去綁定") }}</div>
        </div>
      </div>
    </div>
    <div v-if="$isInMobile" class="flex-middle itrm">
      <div
        class="itrm-title "
        :class="{ active: number === '1' }"
        @click="tab('1')"
      >
        {{ $t("收益釋放") }}
      </div>
      <div
        class="itrm-title"
        :class="{ active: number === '2' }"
        @click="tab('2')"
      >
        {{ $t("額外獎勵") }}
      </div>
    </div>
    <div class="bottom-table flex">
      <div
        class="table-box flex-item mr table-box-odd"
        v-if="!$isInMobile || ($isInMobile && number === '1')"
      >
        <div class="table-odd-top flex-between bg-primary">
          <div class="odd-top-left flex-middle">
            <span>{{ $t("收益釋放：") }}</span>
            <span class="odd-top-num">￥200.000000</span>
          </div>
          <div class="odd-top-btn">
            {{ $t("點擊領取") }}
          </div>
        </div>
        <div class="table-title">{{ $t("購買記錄") }}</div>
        <el-table :data="tableData" stripe style="width: 100%">
          <el-table-column
            prop="address"
            :label="$t('活動名稱')"
            :width="$isInMobile ? wids : ''"
          >
          </el-table-column>
          <el-table-column
            prop="ping"
            :label="$t('購買數量')"
            :width="$isInMobile ? wids : ''"
          >
          </el-table-column>
          <el-table-column
            prop="account"
            :label="$t('鎖倉時長')"
            :width="$isInMobile ? wids : ''"
          >
          </el-table-column>
          <el-table-column
            prop="account"
            :label="$t('已釋放數量')"
            :width="$isInMobile ? wids : ''"
          >
          </el-table-column>
        </el-table>
        <section class="pagition-section">
          <el-pagination
            layout="prev, pager, next"
            :total="total"
            background
            @current-change="handelCurrentPage"
            :page-size="limit"
            :page-count="5"
          >
          </el-pagination>
        </section>
      </div>
      <div
        class="table-box flex-item mr table-box-odd"
        v-if="!$isInMobile || ($isInMobile && number === '2')"
      >
        <div class="table-odd-top flex-between bg-orange">
          <div class="odd-top-left flex-middle">
            <span>{{ $t("額外獎勵：") }}</span>
            <span class="odd-top-num">￥200.000000</span>
          </div>
          <div class="odd-top-btn">{{ $t("點擊領取") }}</div>
        </div>
        <div class="table-title">{{ $t("購買記錄") }}</div>
        <el-table :data="tableData" stripe style="width: 100%">
          <el-table-column
            prop="address"
            :label="$t('直推地址')"
            :width="$isInMobile ? wids : ''"
          >
          </el-table-column>
          <el-table-column
            prop="ping"
            :label="$t('額外獎勵')"
            :width="$isInMobile ? wids : ''"
          >
          </el-table-column>
          <el-table-column
            prop="account"
            :label="$t('獎勵時間')"
            :width="$isInMobile ? wids : ''"
          >
          </el-table-column>
        </el-table>
        <section class="pagition-section">
          <el-pagination
            layout="prev, pager, next"
            :total="total"
            background
            @current-change="handelCurrentPage"
            :page-size="limit"
            :page-count="5"
          >
          </el-pagination>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
export default {
  setup() {
    const data = reactive({
      number: "1",
      page: 1,
      total: 40,
      limit: 10,
      wids: "200",
      hide: false,
      tableData: [
        {
          address: "0x3e739c7e55096eb66224e423b4d25449c76057c3",
          ping: "275884444444",
          account: "0筆交易已發送"
        },
        {
          address: "0x3e739c7e55096eb66224e423b4d25449c76057c3",
          ping: "275884444444",
          account: "0筆交易已發送"
        },
        {
          address: "0x3e739c7e55096eb66224e423b4d25449c76057c3",
          ping: "275884444444",
          account: "0筆交易已發送"
        },
        {
          address: "0x3e739c7e55096eb66224e423b4d25449c76057c3",
          ping: "275884444444",
          account: "0筆交易已發送"
        }
      ],
      tab: type => {
        if (data.number === type) {
          return;
        }
        data.number = type;
      },
      select: "usdt",
      handelCurrentPage: val => {
        data.page = val;
        data.getTableList();
      },
      getTableList: () => {
        // data.loading = true;
        //   getList({
        //     wallet_name: data.type,
        //     page: data.page,
        //     limit: data.limit
        //   })
        //     .then(res => {
        //       data.loading = false;
        //       data.tableData = res.data.data;
        //       data.total = res.data.total;
        //     })
        //     .catch(() => {
        //       data.loading = false;
        //     });
      }
    });
    const refData = toRefs(data);
    return {
      ...refData
    };
  }
};
</script>
<style lang="scss" scoped>
.top-row {
  margin: -100px auto 0;
  max-width: 1400px;
  background: #fff;
  border-radius: 20px;
  padding: 32px 0;
  .row-item {
    padding: 0 30px;
    position: relative;
    height: 200px;
    .row-text-line {
      line-height: 2.5;
      font-size: 16px;
      .text-left {
        color: #666666;
      }
      .text-right {
        color: #111;
      }
    }
    .row-item-left-btn {
      cursor: pointer;
      position: absolute;
      bottom: 0;
      right: 40px;
      width: 190px;
      line-height: 50px;
      border-radius: 25px;
      background: #0956d0;
      color: #fff;
      font-size: 22px;
      text-align: center;
    }
    .ido-top {
      display: block;
      width: 170px;
      margin-right: 60px;
    }
    .item-right-box {
      flex: 1;
      font-size: 20px;
      text-align: center;
      position: relative;
      height: 200px;
      .text-color {
        color: #0a0f17;
        margin-top: 20px;
      }
      .right-btn {
        cursor: pointer;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 190px;
        line-height: 50px;
        border-radius: 25px;
        background: #ffb400;
        color: #fff;
        margin: 0 auto;
        &.disabled {
          background: #999;
          color: #fff;
        }
      }
    }
  }
}
.address-list {
  max-width: 1400px;
  margin: 30px auto 80px;
}
.bottom-table {
  margin: 30px auto;
  max-width: 1400px;
  .table-box {
    margin: 0 auto;
    .table-odd-top {
      height: 80px;
      border-radius: 20px 20px 0 0;
      &.bg-primary {
        background: #0956d0;
        padding: 0 24px;
      }
      &.bg-orange {
        background: #ffb400;
        padding: 0 24px;
      }
      .odd-top-left {
        color: #fff;
        font-size: 16px;
        .odd-top-num {
          font-size: 20px;
        }
      }
      .odd-top-btn {
        color: #fff;
        font-size: 16px;
        border: 1px solid #fff;
        line-height: 46px;
        border-radius: 23px;
        width: 120px;
        text-align: center;
      }
    }
  }
  .table-box:nth-child(1) {
    margin-right: 30px;
  }
}

@media screen and (max-width: 480px) {
  .top-row {
    background: none;
    flex-direction: column-reverse;
    .row-item {
      background: #fff;
      padding: 15px 15px 0;
      margin: 15px;
      border-radius: 10px;
      width: 90%;
      .row-item-left-btn {
        // width: 120px;
        line-height: 40px;
        font-size: 15px;
        right: 32px;
        bottom: 15px;
        position: static;
        width: 100%;
        border-top: 1px solid #e6e6e6;
        background: none;
        color: #0956d0;
        border-radius: 0;
      }
      .ido-top {
        width: 150px;
        margin-right: 30px;
      }
      .item-right-box {
        font-size: 15px;
        display: flex;
        .right-btn {
          width: 120px;
          line-height: 40px;
          position: relative;
        }
      }

      .row-text-line {
        font-size: 15px;
        justify-content: space-between;
      }
    }
    .row-item:last-child {
      padding: 0;
    }
    .el-1px-l {
      margin-top: 10px;
    }
    .el-1px-l::after {
      border-left: 0;
    }
  }
  .bottom-table {
    flex-direction: column;
    align-items: center;
    margin: 20px 15px 30px;
    .table-box:nth-child(1) {
      margin: 0 auto;
    }
  }
  .bottom-table {
    .table-box {
      width: 100%;
      .table-odd-top {
        .odd-top-left {
          font-size: 15px;
          .odd-top-num {
            font-size: 15px;
            font-weight: bold;
          }
        }
        .odd-top-btn {
          line-height: 32px;
          width: 100px;
          font-size: 13px;
        }
      }
    }
    .table-box:nth-child(1) {
      margin-bottom: 30px;
    }
  }
  .bottom-table .table-box .table-odd-top {
    height: 50px;
  }
  .bottom-table .table-box .table-odd-top.bg-primary {
    padding: 0 15px;
  }
  .bottom-table .table-box .table-odd-top.bg-orange {
    padding: 0 15px;
  }
  .table-title {
    font-size: 16px;
    color: #0956d0;
  }

  .item {
    width: 100%;
    padding: 15px;
    .item-flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: -61px;
      .item-nav {
        .primary-color {
          font-weight: bold;
          font-size: 18px;
          color: #fff;
        }
        .text-color {
          font-size: 13px;
          color: #fff;
          margin-top: 10px;
        }
      }
      .right-btn {
        font-size: 15px;
        padding: 7px 13px;
        border: 1px solid #fff;
        border-radius: 20px;
        color: #fff;
      }
    }
  }
  .topbg-wrapper .top-bg {
    height: 260px;
    display: block;
  }
  .top-row {
    margin-top: -170px !important;
    padding: 25px 0 5px;
  }
  .itrm {
    margin: 0 15px;
    .itrm-title {
      width: 50%;
      text-align: center;
      font-size: 15px;
      color: #666666;
      position: relative;
    }
    .active {
      font-size: 16px;
      color: #0956d0;
      font-weight: bold;
    }
    .active::before {
      display: block;
      content: "";
      position: absolute;
      width: 32px;
      height: 2px;
      border-radius: 5px;
      background: #0956d0;
      bottom: -7px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  /deep/ .table-box .el-table thead tr th {
    background: #f7f7f7;
  }
}
</style>
